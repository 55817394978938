import { useCallback, useEffect, useMemo, useState } from 'react'
// import { NoteStatus, getNotesAsync } from '../redux/notes'
import { UserOutlined } from '@ant-design/icons'
import { Avatar, Button, Card, Flex, Image, Spin } from 'antd'
import { format } from 'date-fns'
import { IssuesCard } from 'tabs/issuesCard'
import { convertUtcToEst } from 'utils/DateAndTimeFormat'
import { calculateAge } from 'utils/Dob'
import { getUserTimezone } from 'utils/GetUserTimezone'
import { birthdayIconColor, shouldShowBday } from 'utils/ShouldShowBday'
import {
  AgeBadge,
  BirthdayIcon,
  BirthdayLabel,
  ButtonContainer,
  CardWrapper,
  Container,
  Content,
  FlashingMetricCard,
  HeaderText,
  Label,
  LogoLabel,
  MetricCard,
  MetricTitle,
  MetricValue,
  MetricWrapper,
  NameText,
  NameWrapper,
  PopUp,
  Section,
  Span,
  Text,
  TimeStamp,
  TreatmentType,
  TreatmentTypeContainer,
} from './LobbyTab.styled'
import { useLobbyPolling } from '../../hooks/useLobbyPolling'
import birthDayLogo from '../../images/birthdayLogo.svg'
import Cross from '../../images/cross.svg'
import KiroLogo from '../../images/kiro-logo-full.svg'
import KiroLogoRed from '../../images/kiro-Logo-Red.svg'
import Patient from '../../images/noPatients.svg'
import {
  checkInPatientAsync,
  getTodayVisitAsync,
  loadLobbyPreviousAsync,
  patientQuestionarieModalAsync,
  patientVisitModalAsync,
  resetCheckInPatientStatus,
} from '../../redux/lobby'
import { NoteStatus, getNotesModalAsync } from '../../redux/notes'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { APIStatus } from '../../redux/types'

type CurrentPatientDetails = {
  patientId: string | null
  visitId: string | null
  clinicId: string | null
  initialWaiver: boolean | null
  isQuestionareCompleted: boolean | null
  profilePic: string | null
  dob: string | null
  isUpcoming: boolean | null
}

export default function LobbyTab() {
  const queue = useAppSelector((state) => state.lobby.queue)
  const upcoming = useAppSelector((state) => state.lobby.todayVisit)
  const previousPatients = useAppSelector((state) => state.lobby.previousPatients)
  const dispatch = useAppDispatch()
  const previousPatientsStatus = useAppSelector((state) => state.lobby.previousPatientsStatus)
  const queueStatus = useAppSelector((state) => state.lobby.queueStatus)
  const clinicId = useAppSelector((state) => state.bed.clinic?.id)
  const [currentPatientDetails, setCurrentPatientDetails] = useState<CurrentPatientDetails>({
    patientId: null,
    visitId: null,
    clinicId: null,
    initialWaiver: null,
    isQuestionareCompleted: null,
    profilePic: null,
    dob: null,
    isUpcoming: null,
  })
  const [detailsModalOpen, setDetailsModalOpen] = useState(false)

  const timeStamp = useCallback((time: string) => {
    const stamp = new Date(time)
    const estTime = convertUtcToEst(stamp)
    return format(estTime, 'h:mm a')
  }, [])

  useLobbyPolling()
  useEffect(() => {
    const newDate = new Date()
    console.log(newDate)
    dispatch(loadLobbyPreviousAsync({ clinicId, date: format(newDate, 'yyyy-MM-dd') }))
    dispatch(getTodayVisitAsync({ timezone: 'America/New_York' }))
  }, [])

  if (queueStatus === APIStatus.pending) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin style={{ marginBottom: '130px' }}></Spin>
      </div>
    )
  } else {
    return (
      <Flex gap={15}>
        <Content>
          <Flex vertical gap={8} style={{ width: '100%' }}>
            <HeaderText>Upcoming | {upcoming.length > 0 ? `${upcoming.length}` : 'None'}</HeaderText>

            {upcoming.length > 0 && (
              <Flex gap={8} vertical style={{ overflowY: 'auto', padding: '2px', height: '100%' }}>
                {upcoming?.map((card) => (
                  <div>
                    <TimeStamp>{card.appointmentTime ? timeStamp(card.appointmentTime) : '-'}</TimeStamp>
                    <Card
                      style={{
                        border: '1px solid #2A2D50',
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                      }}
                      onClick={() => {
                        setCurrentPatientDetails((prev) => ({
                          ...prev,
                          visitId: card.visitId,
                          patientId: card.patientId,
                          clinicId: card.clinicId,
                          initialWaiver: card.initialWaiver,
                          isQuestionareCompleted: card.isQuestionareCompleted,
                          profilePic: card.profilePic,
                          dob: card.dob,
                          isUpcoming: true,
                        }))
                        setDetailsModalOpen(true)
                      }}
                      cover={
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            marginLeft: 20,
                            width: '100%',
                          }}
                        >
                          <div style={{ width: 40 }}>
                            <Avatar src={card.profilePic} style={{ height: 40, width: 40, marginLeft: -10 }}></Avatar>
                          </div>
                          <NameText
                            style={{ maxWidth: card.membership && card.isNew && card.scanDue ? '50%' : 'none' }}
                          >
                            {card?.firstname || card?.lastname
                              ? (card?.firstname || '-') + (card?.lastname ? ' ' + card?.lastname : '-')
                              : '-'}
                          </NameText>

                          <div style={{ display: 'flex', gap: 6, position: 'absolute', right: 10 }}>
                            {card.dob && shouldShowBday(new Date(card?.dob?.slice(0, -1))) && (
                              <BirthdayLabel
                                style={{ backgroundColor: birthdayIconColor(new Date(card?.dob?.slice(0, -1))) }}
                              >
                                <BirthdayIcon src={birthDayLogo}></BirthdayIcon>
                              </BirthdayLabel>
                            )}
                            {card.scanDue && card.membership && <Label>Scan</Label>}
                            {card?.isNew && (
                              <Label
                                style={{
                                  background: card?.amount && card?.amount / 100 === 100 ? '#E33939' : '#007f7c',
                                }}
                              >
                                New
                              </Label>
                            )}
                            {card?.membership && (
                              <div>
                                {card?.membershipStatus === 'in-active' ? (
                                  <LogoLabel src={KiroLogoRed}></LogoLabel>
                                ) : (
                                  <LogoLabel src={KiroLogo}></LogoLabel>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      }
                      key={card.visitId}
                    ></Card>
                  </div>
                ))}
              </Flex>
            )}
          </Flex>
        </Content>
        <Content>
          <Flex vertical gap={8} style={{ width: '100%' }}>
            <HeaderText>Checked-In | {queue.length > 0 ? `${queue.length}` : 'None'}</HeaderText>

            {queue.length > 0 && (
              <Flex gap={8} vertical style={{ overflowY: 'auto', padding: '2px', height: '100%' }}>
                {queue?.map((card) => (
                  <Card
                    style={{
                      border: '1px solid #2A2D50',
                      // height: '66px',
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                    }}
                    onClick={() => {
                      setCurrentPatientDetails((prev) => ({
                        ...prev,
                        visitId: card.id,
                        patientId: card.patientId,
                        clinicId: card.clinicId,
                        initialWaiver: null,
                        isQuestionareCompleted: null,
                        profilePic: null,
                        dob: null,
                        isUpcoming: false,
                      }))
                      setDetailsModalOpen(true)
                    }}
                    cover={
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                          marginLeft: 20,
                          width: '100%',
                        }}
                      >
                        <div style={{ width: 40 }}>
                          <Avatar src={card.profilePic} style={{ height: 40, width: 40, marginLeft: -10 }}></Avatar>
                        </div>
                        <NameText style={{ maxWidth: card.membership && card.isNew && card.scanDue ? '50%' : 'none' }}>
                          {card?.firstname || card?.lastname
                            ? (card?.firstname || '-') + (card?.lastname ? ' ' + card?.lastname : '-')
                            : '-'}
                        </NameText>

                        <div style={{ display: 'flex', gap: 6, position: 'absolute', right: 10 }}>
                          {card.dob && shouldShowBday(new Date(card?.dob?.slice(0, -1))) && (
                            <BirthdayLabel style={{ background: birthdayIconColor(new Date(card?.dob?.slice(0, -1))) }}>
                              <BirthdayIcon src={birthDayLogo}></BirthdayIcon>
                            </BirthdayLabel>
                          )}
                          {card.scanDue && card.membership && <Label>Scan</Label>}
                          {card?.isNew && (
                            <Label
                              style={{
                                background: card?.amount && card?.amount / 100 === 100 ? '#E33939' : '#007f7c',
                              }}
                            >
                              New
                            </Label>
                          )}
                          {card?.membership && (
                            <div>
                              {card?.membershipStatus === 'in-active' ? (
                                <LogoLabel src={KiroLogoRed}></LogoLabel>
                              ) : (
                                <LogoLabel src={KiroLogo}></LogoLabel>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    }
                    key={card.visitId}
                  ></Card>
                ))}
              </Flex>
            )}
          </Flex>
        </Content>
        <Content>
          <Flex vertical gap={8} style={{ width: '100%' }}>
            <HeaderText>Previous | {previousPatients.length > 0 ? `${previousPatients.length}` : 'None'}</HeaderText>

            {previousPatients.length > 0 && (
              <Flex gap={8} vertical style={{ overflowY: 'auto', padding: '2px', height: '100%' }}>
                {previousPatients?.map((p) => (
                  <Card
                    style={{
                      border: '1px solid #2A2D50',
                      // height: '66px',
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                    }}
                    onClick={() => {
                      setCurrentPatientDetails((prev) => ({
                        ...prev,
                        visitId: p.id,
                        patientId: p.patientId,
                        clinicId: p.clinicId,
                        initialWaiver: null,
                        isQuestionareCompleted: null,
                        profilePic: null,
                        dob: null,
                        isUpcoming: false,
                      }))
                      setDetailsModalOpen(true)
                    }}
                    cover={
                      <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginLeft: 20 }}>
                        <div style={{ width: 40 }}>
                          <Avatar size={40} src={p?.profilePic} style={{ marginLeft: -10 }}></Avatar>
                        </div>
                        <NameText>
                          {p?.firstname || p?.lastname
                            ? (p?.firstname || '-') + (p?.lastname ? ' ' + p?.lastname : '-')
                            : '-'}
                        </NameText>

                        <div style={{ display: 'flex', gap: 6, position: 'absolute', right: 10 }}>
                          {p?.membership && (
                            <div>
                              {p?.membershipStatus === 'in-active' ? (
                                <LogoLabel src={KiroLogoRed}></LogoLabel>
                              ) : (
                                <LogoLabel src={KiroLogo}></LogoLabel>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    }
                    key={p.visitId}
                  ></Card>
                ))}
              </Flex>
            )}
          </Flex>
        </Content>

        {detailsModalOpen && (
          <PopUp
            open={detailsModalOpen}
            centered
            mask={true}
            maskClosable={true}
            footer={null}
            closable={true}
            onCancel={() => {
              setDetailsModalOpen(false)
            }}
            closeIcon={
              <div
                style={{
                  borderRadius: '4px',
                  position: 'absolute',
                  top: -1,
                  right: 10,
                  height: 18,
                  width: 20,
                  background: '#2A2D56',
                }}
                onClick={() => {
                  setDetailsModalOpen(false)
                }}
              >
                <img src={Cross}></img>
              </div>
            }
            // style={{ padding: 0 }}
          >
            <PatientDetailsModal
              patientDetails={currentPatientDetails}
              closeModal={() => {
                setDetailsModalOpen(false)
              }}
            ></PatientDetailsModal>
          </PopUp>
        )}
      </Flex>
    )
  }
}

export function PatientDetailsModal({
  patientDetails,
  closeModal,
}: {
  patientDetails: CurrentPatientDetails
  closeModal: any
}) {
  const dispatch = useAppDispatch()
  const visit = useAppSelector((state) => state.lobby.visitModal)
  const patientNotes = useAppSelector((state) => state.notes.patientNotesModal)
  const patientQuestionaries = useAppSelector((state) => state.lobby.patientQuestionariesModal)
  const visitStatus = useAppSelector((state) => state.lobby.visitModalStatus)
  const questionarieStatus = useAppSelector((state) => state.lobby.patientQuestionarieModalStatus)
  const patientNotesStatus = useAppSelector((state) => state.notes.patientNotesModalStatus)
  const checkInPatientStatus = useAppSelector((state) => state.lobby.checkInPatientStatus)

  const currentPatientNote = useMemo(() => {
    if (patientDetails.patientId) {
      return patientNotes[patientDetails.patientId]
    } else {
      return null
    }
  }, [patientNotes, visit])

  const currentNote = useMemo(() => {
    if (currentPatientNote && currentPatientNote.notes && currentPatientNote.notes.length > 0) {
      let temp = [...currentPatientNote?.notes]

      temp = temp.filter((note) => note.status === NoteStatus.ACCEPTED)

      temp.sort((a, b) => {
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      })

      return temp[temp.length - 1]
    } else {
      return null
    }
  }, [currentPatientNote])

  useEffect(() => {
    dispatch(patientVisitModalAsync({ patientId: patientDetails.patientId }))
    dispatch(patientQuestionarieModalAsync({ patientId: patientDetails.patientId }))
    dispatch(getNotesModalAsync(patientDetails?.patientId || ''))
  }, [patientDetails.patientId])

  useEffect(() => {
    return () => {
      dispatch(resetCheckInPatientStatus())
    }
  }, [])

  if (
    visitStatus === APIStatus.pending ||
    patientNotesStatus === APIStatus.pending ||
    questionarieStatus === APIStatus.pending
  ) {
    return (
      <div
        style={{
          height: '85vh',
          width: '100%',
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin></Spin>
      </div>
    )
  } else {
    return (
      <Container>
        <Flex style={{ height: 70, borderBottom: '1px solid #13152d', padding: 0, flexDirection: 'row' }}>
          <Image preview={false} src={KiroLogo} style={{ padding: '20px 0px 20px 30px' }}></Image>
          {patientDetails.isQuestionareCompleted &&
            patientDetails.profilePic &&
            patientDetails.dob &&
            patientDetails.initialWaiver &&
            patientDetails.isUpcoming && (
              <Button
                style={{
                  width: '85px',
                  height: '28px',
                  display: 'flex',
                  margin: '22px 0px 0px 20px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: checkInPatientStatus === APIStatus.fulfilled ? '#e5e7eb' : '#007f7c',
                  color: checkInPatientStatus === APIStatus.fulfilled ? '#007f7c' : '#e5e7eb',
                  fontSize: '13px',
                  fontWeight: 700,
                }}
                loading={checkInPatientStatus === APIStatus.pending ? true : false}
                type="primary"
                children={
                  checkInPatientStatus === APIStatus.pending
                    ? ''
                    : checkInPatientStatus === APIStatus.fulfilled
                      ? 'Checked In'
                      : 'Check In'
                }
                onClick={() => {
                  if (checkInPatientStatus === APIStatus.idle) {
                    dispatch(
                      checkInPatientAsync({
                        clinicId: patientDetails.clinicId || '',
                        visitId: patientDetails.visitId || '',
                      }),
                    )
                  }
                }}
              ></Button>
            )}
        </Flex>
        <Flex gap={60} style={{ padding: '25px 70px 70px 70px' }}>
          <Section>
            <Flex vertical style={{ width: '100%', justifyContent: 'space-between', marginBottom: '15px' }}>
              <Flex vertical gap={12}>
                <Flex gap={20}>
                  {visit?.isNew && (
                    <Button
                      children={'New'}
                      style={{
                        height: '23px',
                        position: 'absolute',
                        background: visit?.amount && visit?.amount / 100 === 100 ? '#E33939' : '#007f7c',
                        color: '#e5e7eb',
                        width: '50px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        left: 71,
                        top: 230,
                        zIndex: 1,
                        marginLeft: 8,
                        marginTop: -8,
                        fontSize: 12,
                        cursor: 'default',
                      }}
                    ></Button>
                  )}
                  {visit?.user.dob && <AgeBadge>{calculateAge(visit?.user.dob)}</AgeBadge>}
                  {visit?.membership && (
                    <img
                      src={visit?.membershipStatus === 'in-active' ? KiroLogoRed : KiroLogo}
                      style={{
                        height: '30px',
                        position: 'absolute',
                        width: '30px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        left: 71,
                        top: 96,
                        marginLeft: 8,
                        marginTop: 8,
                        zIndex: 1,
                        fontSize: 12,
                        cursor: 'default',
                      }}
                    ></img>
                  )}
                  <Avatar
                    shape="square"
                    // size={220}
                    style={{ borderRadius: 8, minWidth: '160px', minHeight: '160px' }}
                    icon={visit?.user?.profilePic ? <img src={visit?.user?.profilePic ?? ''}></img> : <UserOutlined />}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                      height: 'auto',
                    }}
                  >
                    <NameWrapper>
                      <Span style={{ textTransform: 'uppercase' }}>{visit?.user.firstname}</Span>
                      <Span style={{ textTransform: 'uppercase' }}>{visit?.user.lastname}</Span>
                    </NameWrapper>

                    <MetricWrapper>
                      {visit?.feedbackValue === null ? (
                        <FlashingMetricCard>
                          <MetricTitle>REV</MetricTitle>
                          <MetricValue>{visit?.feedbackValue ?? '-'}</MetricValue>
                        </FlashingMetricCard>
                      ) : (
                        <MetricCard>
                          <MetricTitle>REV</MetricTitle>
                          <MetricValue>{visit?.feedbackValue ?? '-'}</MetricValue>
                        </MetricCard>
                      )}
                      {visit && visit?.metric && visit?.metric?.dst > 7 ? (
                        <FlashingMetricCard>
                          <MetricTitle>DSL</MetricTitle>
                          <MetricValue>{visit?.metric?.dst ?? '-'}</MetricValue>
                        </FlashingMetricCard>
                      ) : (
                        <MetricCard>
                          <MetricTitle>DSL</MetricTitle>
                          <MetricValue>{visit?.metric?.dst ?? '-'}</MetricValue>
                        </MetricCard>
                      )}
                      {visit && visit?.metric && visit?.metric?.apm && parseFloat(visit?.metric?.apm) < 4 ? (
                        <FlashingMetricCard>
                          <MetricTitle>APM</MetricTitle>
                          <MetricValue>{visit?.metric?.apm ?? '-'}</MetricValue>
                        </FlashingMetricCard>
                      ) : (
                        <MetricCard>
                          <MetricTitle>APM</MetricTitle>
                          <MetricValue>{visit?.metric?.apm ?? '-'}</MetricValue>
                        </MetricCard>
                      )}
                      {visit?.metric?.membershipWeek === 4 && (
                        <FlashingMetricCard>
                          <MetricTitle>ZON</MetricTitle>
                          <MetricValue>
                            {visit?.metric?.membershipMonth ?? '-'}.{visit?.metric?.membershipWeek ?? '-'}
                          </MetricValue>
                        </FlashingMetricCard>
                      )}
                      {visit?.metric?.membershipWeek !== 4 && (
                        <MetricCard
                          style={{
                            backgroundColor: visit?.metric?.membershipWeek === 3 ? '#ff0000' : '#10123d',
                            color: visit?.metric?.membershipWeek === 3 ? '#e5e7eb' : '#007f7c',
                          }}
                        >
                          <MetricTitle>ZON</MetricTitle>
                          {visit?.metric?.membershipMonth === 0 && visit?.metric?.membershipWeek === 0 ? (
                            <MetricValue>{'-'}</MetricValue>
                          ) : (
                            <MetricValue>
                              {visit?.metric?.membershipMonth ?? '-'}.{visit?.metric?.membershipWeek ?? '-'}
                            </MetricValue>
                          )}
                        </MetricCard>
                      )}
                      <MetricCard>
                        <MetricTitle>PCR</MetricTitle>
                        <MetricValue>{visit?.subscriptionCount ?? 0}</MetricValue>
                      </MetricCard>
                      <MetricCard>
                        <MetricTitle>NSS</MetricTitle>
                        <MetricValue>{visit?.metric?.scanScore ?? '-'}</MetricValue>
                      </MetricCard>
                    </MetricWrapper>
                    <TreatmentTypeContainer>
                      <TreatmentType
                        children={'Corrective'}
                        style={{
                          background: visit?.treatmentType === 'corrective' ? '#007F7C' : '#10123d',
                          color: visit?.treatmentType === 'corrective' ? '#fffeff' : '#e5e7eb',
                          border: visit?.treatmentType !== 'corrective' ? '1px solid #343755' : '1px solid #10123d',
                        }}
                      ></TreatmentType>
                      <TreatmentType
                        children={'Symptomatic'}
                        style={{
                          background: visit?.treatmentType === 'symptomatic' ? '#007F7C' : '#10123d',
                          color: visit?.treatmentType === 'symptomatic' ? '#fffeff' : '#e5e7eb',
                          border: visit?.treatmentType !== 'symptomatic' ? '1px solid #343755' : '1px solid #10123d',
                        }}
                      ></TreatmentType>
                    </TreatmentTypeContainer>
                  </div>
                </Flex>
                <IssuesCard style={{ overflowY: 'scroll' }} patientQuestionaries={patientQuestionaries}></IssuesCard>
              </Flex>
              <ButtonContainer>
                <Button
                  type="primary"
                  style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#007f7c',
                    // opacity: isPollingInProgress ? 0.5 : 1,
                  }}
                  onClick={closeModal}
                >
                  Done
                </Button>
              </ButtonContainer>
            </Flex>
          </Section>
          <Section>
            <Flex
              gap={17}
              style={{
                flexDirection: 'row-reverse',
                alignContent: 'flex-end',
                flex: '1',
                width: '100%',
              }}
            >
              {currentNote && (
                <Flex vertical gap={8} style={{ width: '100%', marginBottom: '15px' }}>
                  <CardWrapper style={{ marginTop: 12 }}>
                    <Text style={{ zIndex: '20' }}>Subjective</Text>
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        resize: 'none',
                        padding: '0px 10px 0px 10px',
                        marginTop: 20,
                      }}
                    >
                      {currentNote?.subjective}
                    </div>
                  </CardWrapper>

                  <CardWrapper>
                    <Text style={{ zIndex: '20' }}>Objective</Text>
                    <div
                      style={{
                        height: '100%',
                        width: '100%',
                        resize: 'none',
                        padding: '0px 10px 0px 10px',
                        marginTop: 20,
                      }}
                    >
                      {currentNote?.objective}
                    </div>
                  </CardWrapper>
                  <CardWrapper>
                    <Text style={{ zIndex: '20' }}>Assessment</Text>
                    <div
                      style={{
                        height: '100%',
                        width: '100%',
                        resize: 'none',
                        padding: '0px 10px 0px 10px',
                        marginTop: 20,
                      }}
                    >
                      {currentNote?.assessment}
                    </div>
                  </CardWrapper>
                  <CardWrapper>
                    <Text style={{ zIndex: '20' }}>Plan</Text>
                    <div
                      style={{
                        height: '100%',
                        width: '100%',
                        resize: 'none',
                        padding: '0px 10px 0px 10px',
                        marginTop: 20,
                      }}
                    >
                      {currentNote?.plan}
                    </div>
                  </CardWrapper>
                </Flex>
              )}
            </Flex>
          </Section>
        </Flex>
      </Container>
    )
  }
}
