export function shouldShowBday(birthDate: Date): boolean {
  const birthDay = birthDate.getDate()
  const birthMonth = birthDate.getMonth()
  const rangeInDays = 5

  const today = new Date()
  today.setHours(0, 0, 0, 0) // Set today's time to midnight

  const currentYear = today.getFullYear()

  // Create birthday dates for this year, previous year, and next year
  const birthdayThisYear = new Date(currentYear, birthMonth, birthDay)
  birthdayThisYear.setHours(0, 0, 0, 0)

  const birthdayLastYear = new Date(currentYear - 1, birthMonth, birthDay)
  birthdayLastYear.setHours(0, 0, 0, 0)

  const birthdayNextYear = new Date(currentYear + 1, birthMonth, birthDay)
  birthdayNextYear.setHours(0, 0, 0, 0)

  // Calculate the range start and end dates
  const startRange = new Date(today)
  startRange.setDate(today.getDate() - rangeInDays)
  startRange.setHours(0, 0, 0, 0)

  const endRange = new Date(today)
  endRange.setDate(today.getDate() + rangeInDays)
  endRange.setHours(0, 0, 0, 0)

  // Check if any of the birthdays fall within the range
  return (
    (birthdayThisYear >= startRange && birthdayThisYear <= endRange) ||
    (birthdayLastYear >= startRange && birthdayLastYear <= endRange) ||
    (birthdayNextYear >= startRange && birthdayNextYear <= endRange)
  )
}

export function birthdayIconColor(birthDate: Date) {
  const rangeInDays = 5

  const birthDay = birthDate.getDate()
  const birthMonth = birthDate.getMonth()
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  const currentYear = today.getFullYear()

  const birthdayThisYear = new Date(currentYear, birthMonth, birthDay)
  birthdayThisYear.setHours(0, 0, 0, 0)

  const birthdayLastYear = new Date(currentYear - 1, birthMonth, birthDay)
  birthdayLastYear.setHours(0, 0, 0, 0)

  const birthdayNextYear = new Date(currentYear + 1, birthMonth, birthDay)
  birthdayNextYear.setHours(0, 0, 0, 0)

  const startRange = new Date(today)
  startRange.setDate(today.getDate() - rangeInDays)
  startRange.setHours(0, 0, 0, 0)

  const endRange = new Date(today)
  endRange.setDate(today.getDate() + rangeInDays)
  endRange.setHours(0, 0, 0, 0)

  if (birthdayThisYear >= startRange && birthdayThisYear <= endRange) {
    if (birthdayThisYear.getTime() === today.getTime()) {
      return '#007f7c'
    } else if (birthdayThisYear > today) {
      return '#4794EF'
    } else {
      return '#ff0000'
    }
  }

  if (birthdayLastYear >= startRange && birthdayLastYear <= endRange) {
    return '#ff0000'
  }

  if (birthdayNextYear >= startRange && birthdayNextYear <= endRange) {
    return '#4794EF'
  }

  return ''
}
